// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

// General sizes
$padding: 1rem;

// Media sizes
$desktop: "(min-width: 1001px)";
$desktop-width: 64%;
$desktop-left: calc((100% - #{$desktop-width}) / 2);

$mobile: "(max-width: 1000px)";
$mobile-width: calc(100% - #{$padding} - #{$padding});

$hover-supported: "(hover: hover)";
$hover-not-supported: "(hover: none)";

// Page specific sizes
$article-padding: $padding;

$footer-padding: $padding;
$footer-size: 2rem;

$houses-height: 120px;
$houses-width: 780px;

$homegrid-mobile-size: calc(100vw - #{$padding} - #{$padding});
$homegrid-padding: $padding;
$homegrid-size: calc(((100vw * 0.64) - #{$padding} - #{$padding} - #{$homegrid-padding} - #{$homegrid-padding}) / 3);

$imagegrid-cross-size: 48px;
$imagegrid-mobile-size: calc(100vw - #{$padding} - #{$padding});
$imagegrid-padding: $padding;
$imagegrid-size: 160px;

$landie-height: 44px;
$landie-width: 80px;

$header-border-size: 1px;
$header-line-height: 1.5rem;
$header-padding: $padding;
$header-size: 2rem;

$navigation-padding: $padding;
$navigation-size: 5rem;
$navigation-line-height: calc(#{$navigation-size} - #{$navigation-padding} - #{$navigation-padding});
$navigation-font-large: calc(#{$navigation-size} - #{$navigation-padding} - #{$navigation-padding});
$navigation-font-small: calc(#{$navigation-size} - #{$navigation-padding} - #{$navigation-padding} - 1.75rem);
$navigation-social-image-size: 32px;