// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

a {
    font-weight: normal;

    &:link, &:visited {
        color: $link;
        text-decoration: none;
    }

    &:focus, &:hover {
        text-decoration: underline;
    }

    // Set the visual addresses from data here, note that these aren't in the DOM

    &[data-domain]::after {
        content: attr(data-name) "@" attr(data-domain) "." attr(data-tld)
    }

    &[data-local]::after {
        content: "0" attr(data-area) " " attr(data-local)
    }
}

body {
    background-color: $background;
    color: $tertiary;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
}
