// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

footer {
    background-color: $tertiary;
    color: $foreground;
    display: grid;
    justify-items: center;
    min-height: $footer-size;
    position: relative;

    @media #{$desktop} {
        bottom: 0;
        column-gap: 0.25rem;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 $desktop-left;
        position: fixed;
        width: $desktop-width;
    }

    @media #{$mobile} {
        padding: 0 $footer-padding;
        width: $mobile-width;
    }

    & p {
        line-height: $footer-size;
        margin: 0;

        & a, a:link, a:visited {
            color: $foreground;

            & span.colour-alternate {
                color: $background;
            }
        }
    }

    // The Landie that drives across the black bar
    &::after {
        background-image: url("/assets/images/layout/landie.png");
        background-position-x: -$landie-width;
        background-position-y: 0px;
        background-repeat: no-repeat;
        background-size: $landie-width $landie-height;
        bottom: 100%;
        content: "";
        display: inline-block;
        height: $landie-height;
        left: 0;
        pointer-events: none;
        position: absolute;
        // Give ourselves 8 extra frames for the javascript to set the next values
        transition: background-position-x 628ms linear, background-position-y 628ms linear;
        width: 100%;
    }

    // The row of houses displayed above the footer black bar
    &::before {
        background-image: url("/assets/images/layout/buildings.png");
        background-repeat: no-repeat;
        bottom: 100%;
        content: "";
        display: inline-block;
        height: $houses-height;
        left: calc((100% - #{$houses-width}) / 2);
        max-width: $houses-width;
        pointer-events: none;
        position: absolute;
        right: 0;
    }
}
