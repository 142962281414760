// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

header {
    background-color: $foreground;
    display: flex;
    flex-wrap: wrap;
    min-height: calc(#{$navigation-size} - #{$navigation-padding} - #{$navigation-padding});

    @media #{$desktop} {
        align-items: baseline;
        padding: $navigation-padding $desktop-left;
        width: $desktop-width;
    }

    @media #{$mobile} {
        align-items: center;
        padding: $navigation-padding $navigation-padding;
        width: $mobile-width;
    }

    // The burger menu button only shown on mobile
    & > a[href="#menu"] {
        @media #{$desktop} {
            display: none;
        }

        @media #{$mobile} {
            flex-basis: calc(#{$navigation-line-height} / 2);
        }
    }

    // Styling for the headers within the nav
    & h1, h2, h3 {
        margin: 0;

        & a, a:link, a:visited {
            color: $tertiary;
        }
    }

    & h1 {
        font-family: 'Times New Roman', Times, serif;
        font-size: $navigation-font-large;

        // Do not shrink the title on desktop, shrink the nav section
        @media #{$desktop} {
            flex-shrink: 0;
            line-height: $navigation-line-height;
            padding-right: calc(#{$navigation-padding} / 2);
        }

        // Set full padding after 1100px, otherwise this causes wrapping
        @media (min-width: 1100px) {
            padding-right: $navigation-padding;
        }

        // Title fills as much width as available on mobile (unless caught by the min/max below)
        @media #{$mobile} {
            font-size: calc((100vw - #{$navigation-line-height}) / 7.5);
            flex-basis: calc(100% - (#{$navigation-line-height} / 2) - #{$navigation-padding} - #{$navigation-padding});
        }

        // Ensure we don't go larger than navigation-font-large
        @media #{$mobile} and (min-width: calc((#{$navigation-font-large} * 7.5) + #{$navigation-line-height})) {
            font-size: $navigation-font-large;
        }

        // Ensure we don't go smaller than navigation-font-small
        @media #{$mobile} and (max-width: calc((#{$navigation-font-small} * 7.5) + #{$navigation-line-height})) {
            font-size: #{$navigation-font-small}
        }
    }

    & h2 {
        font-size: $navigation-font-small;

        & a {
            padding: 0 calc(#{$navigation-padding} / 2);

            // Set full padding after 1100px, otherwise this causes wrapping
            @media (min-width: 1100px) {
                padding: 0 $navigation-padding;
            }
        }
    }

    // Sizing for the image of the burger and close buttons
    & img.button {
        display: block;
        height: calc(#{$navigation-line-height} / 2);
        padding: $navigation-padding;
        width: calc(#{$navigation-line-height} / 2);
    }

    // Container for menu items which is horizontal flex on desktop
    // and vertical flex on mobile (hidden until burger menu is selected)
    & nav {
        @media #{$desktop} {
            align-items: baseline;
            display: flex;
            flex: auto;
            flex-wrap: wrap;
            position: relative;
        }

        @media #{$mobile} {
            align-items: center;
            display: none;
            flex-direction: column;
            text-align: center;
        }

        // Use padding instead of row-gap to increase clickable area on mobile
        & > * {
            @media #{$mobile} {
                padding: calc(#{$navigation-padding} * 1.5) 0;
            }
        }

        // The close button within the menu only shown on mobile
        & > a[href="#"], a[href="#menu"] {
            padding: $navigation-padding 0;  // img inside already has padding

            @media #{$desktop} {
                display: none;
            }
        }

        // Show the burger menu when selected
        &:target {
            display: flex;
            flex-basis: 100%;

            // When the burger menu is shown, hide the burger button
            & ~ a[href="#menu"] {
                display: none;
            }
        }
    }

    & nav#menu {
        & > a.social {
            // Push social links to end of the flex
            &.-first {
                @media #{$desktop} {
                    margin-left: auto;
                }
            }

            & figure {
                margin: 0;

                @media #{$mobile} {
                    align-items: center;
                    display: flex;
                }

                & figcaption {
                    color: $tertiary;

                    & h2 {
                        font-weight: normal;
                    }

                    @media #{$desktop} {
                        display: none;
                    }
                }

                & img {
                    display: block;
                    height: $navigation-social-image-size;
                    padding-left: $navigation-padding;
                }
            }
        }

        & > h2 {
            @media #{$desktop} {
                padding-top: $navigation-padding;
            }

            &.services-dropdown {
                @media #{$mobile} {
                    display: none;
                }
            }

            &.services-mobile {
                @media #{$desktop} {
                    display: none;
                }
            }

            &.services-dropdown:hover + ul#services-dropdown {
                display: block;
            }
        }

        ul#services-dropdown {
            display: none;
            margin: 0;

            @media #{$desktop} {
                background-color: $foreground;
                padding: 0;
                padding-bottom: $navigation-padding;
                list-style-type: none;
                position: absolute;
                top: 100%;
                z-index: 1;  // TODO: have variables for z-index ?
            }

            &:hover, &:target {
                display: block;
            }

            &:target > li.close {
                display: block;
            }

            & li {
                &.close {
                    display: none;
                }

                & > h2 {
                    padding-top: $navigation-padding;

                    @media #{$mobile} {
                        padding: calc(#{$navigation-padding} * 1.5) 0;

                        &:first-of-type {
                            padding-top: 0;
                        }

                        &:last-of-type {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    & > nav#services {
        @media #{$desktop} {
            display: none;
        }
    }
}