// SPDX-FileCopyrightText: 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

section.contact-flex {
    align-items: center;
    display: flex;

    @media #{$desktop} {
        flex-direction: row-reverse;
        min-height: inherit;
    }

    @media #{$mobile} {
        flex-direction: column;
    }

    & address {
        font-style: normal;
        text-align: center;

        @media #{$desktop} {
            display: block;
            padding: #{$article-padding};
            width: calc(50% - (#{$article-padding} / 2));
        }

        & > h1#contact {
            @media #{$desktop} {
                margin: $article-padding 0;
            }
        }
    }

    & img {
        @media #{$desktop} {
            display: block;
            max-width: calc(50% - (#{$article-padding} / 2));
            padding: #{$article-padding};
        }
    }
}
