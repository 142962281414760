// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

figure#image-modal {
    background-color: rgba($tertiary, 0.5);
    left: 0;
    height: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    text-align: center;
    width: 100%;

    &::after {
        background-color: rgba($foreground, 0.75);
        background-image: url("/assets/images/layout/cross.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: $imagegrid-cross-size $imagegrid-cross-size;
        content: "";
        cursor: pointer;
        filter: invert(1.0);
        height: $imagegrid-cross-size;
        right: 0;
        padding: $imagegrid-padding;
        position: absolute;
        top: 0;
        width: $imagegrid-cross-size;
    }

    & figcaption {
        background-color: rgba($tertiary, 0.75);
        bottom: 0;
        color: $foreground;
        left: 0;
        padding: $imagegrid-padding;
        pointer-events: none;
        position: fixed;
        right: 0;
        text-align: center;
    }

    & img {
        max-height: calc(100% - #{$imagegrid-padding} - #{$imagegrid-padding});
        max-width: calc(100% - #{$imagegrid-padding} - #{$imagegrid-padding});
        padding: $imagegrid-padding;
        pointer-events: none;
    }
}

section.image-grid {
    @media #{$desktop} {
        column-gap: $imagegrid-padding;
        display: grid;
        grid-template-columns: repeat(auto-fill, $imagegrid-size);
        grid-template-rows: repeat(auto-fill, $imagegrid-size);
        justify-content: center;
        padding: $imagegrid-padding 0;
        row-gap: $imagegrid-padding;
    }

    // On mobile we only show the first image in a grid, unless -all-images is also set
    &.-all-images {
        a {
            @media #{$mobile} {
                display: block;
            }
        }
    }

    a {
        @media #{$desktop} {
            height: $imagegrid-size;
            width: $imagegrid-size;
        }

        @media #{$mobile} {
            display: none;
            height: $imagegrid-mobile-size;
            padding: calc(#{$imagegrid-padding} / 2) 0;
            width: $imagegrid-mobile-size;

            &:first-of-type {
                display: block;
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        figure {
            height: 100%;
            margin: 0;
            width: 100%;

            figcaption {
                display: none;
            }

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }
}
