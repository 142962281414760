// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

section.home-grid {
    padding: $homegrid-padding 0;

    @media #{$desktop} {
        column-gap: $homegrid-padding;
        display: grid;
        grid-template-areas: "a a b"
                             "a a c"
                             "d e e";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: $homegrid-size $homegrid-size $homegrid-size;
        row-gap: $homegrid-padding;
    }

    a {
        @media #{$desktop} and #{$hover-supported} {
            &:hover {
                & figcaption {
                    bottom: 10%;
                    opacity: 1.0;
                }

                & figure::before {
                    opacity: 0.85;
                }
            }
        }

        @media #{$mobile} {
            display: block;
            height: $homegrid-mobile-size;
            padding: calc(#{$homegrid-padding} / 2) 0;

            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        &:nth-of-type(1n) {
            grid-area: a;
        }

        &:nth-of-type(2n) {
            grid-area: b;
        }

        &:nth-of-type(3n) {
            grid-area: c;
        }

        &:nth-of-type(4n) {
            grid-area: d;
        }

        &:nth-of-type(5n) {
            grid-area: e;
        }
    }

    figure {
        height: 100%;
        margin: 0;
        position: relative;
        width: 100%;

        // Shade over image and below text
        &::before {
            background: linear-gradient(transparent 50%, $tertiary 95%);
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            @media #{$desktop} and #{$hover-supported} {
                opacity: 0.0;
                transition: opacity linear 300ms;
            }
        }

        figcaption {
            color: $foreground;
            position: absolute;
            text-align: center;
            width: 100%;

            h2 {
                margin: 0;
            }

            @media #{$desktop} and #{$hover-supported} {
                bottom: 0;
                opacity: 0.0;
                transition: bottom linear 300ms, opacity linear 300ms;
            }

            @media #{$mobile}, #{$desktop} and #{$hover-not-supported} {
                bottom: 10%;
            }
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}