// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

article {
    background-color: $foreground;
    color: $tertiary;
    display: inline-block;
    // min-height is viewport height - header - header border - padding - houses - footer
    min-height: calc(100vh - #{$navigation-size} - #{$header-size} - #{$header-border-size} - #{$article-padding} - #{$houses-height} - #{$footer-size});
    padding-left: $article-padding;
    padding-right: $article-padding;

    @media #{$desktop} {
        margin-left: $desktop-left;
        padding-bottom: calc(#{$article-padding} + #{$houses-height} + #{$footer-size});
        width: calc(#{$desktop-width} - #{$article-padding} - #{$article-padding});
    }

    @media #{$mobile} {
        padding-bottom: calc(#{$article-padding} + #{$houses-height});
        text-align: justify;
        width: $mobile-width;
    }

    a {
        font-weight: bold;
    }

    h1 {
        font-size: 2rem;
    }

    img {
        max-width: 100%;
        pointer-events: none;

        &.left {
            float: left;
            padding-right: $article-padding;
        }

        &.right {
            float: right;
            padding-left: $article-padding;
        }
    }
}
