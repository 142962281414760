// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

aside {
    align-items: center;
    background-color: $background;
    border-bottom: $header-border-size solid darken($background, 20);
    display: flex;
    justify-content: center;
    min-height: calc(#{$header-size} - #{$header-border-size});
    padding: 0 $header-padding;
    text-align: center;

    & h4 {
        color: $foreground;
        line-height: $header-line-height;
        margin: 0;
        padding: calc((#{$header-size} - #{$header-line-height} - #{$header-border-size}) / 2) 0;
    }
}